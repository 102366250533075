body {
  border-top: 10px solid black;
  font-family: "Public Sans", "Nimbus Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway"
}

header {
  min-height: 100px;
  position: relative;
}

.container {
  max-width: 55rem;
}

main {
  @media (max-width: 768px) {
    padding-top: 100px;
  }
}

.blog-page {
  max-width: 45rem;
  margin-left: auto;
  margin-right: auto;
}

a {
  font-weight: bold;
  text-decoration: none;
}

:root {
  --link-color: var(--gray-700);
  --body-font-size: 18px;
}

a:hover {
  color: #007eff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --link-color: var(--gray-300);
  }
}

ul {
  list-style: square;
}

.footer {
  text-align: right;
}

/* Navbar stuff */

header.masthead .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul#navbar {
  list-style: none;
  display: flex;
  margin-top: 12px;
  float: right;
}

.site-title {
  margin-top: 0px;
  text-wrap: nowrap;
  a, a:visited {
    color: var(--heading-color);
  }
  a:hover {
    color: #007eff;
  }
}

ul#navbar li {
  padding: 0px;
  margin: 0px;
  margin-left: 10px;
}

ul#navbar li a {
  font-weight: normal;
  background: black;
  color: white;
  padding: 9px 13px;
  text-decoration: none;
  border: 1px solid #ccc;
}

ul#navbar li a:hover {
  background: white;
  color: black;
}

.nav-trigger {
  display: none;
}

.menu-icon {
  display: none;
}

.page-link {
  color: black;
  line-height: 1.5;

  // Gaps between nav items, but not on the last one
  &:not(:last-child) {
    margin-right: 20px;
  }
}

header.masthead {
  @media (max-width: 768px) {
    position: absolute;
    padding-right: 40px;
    top: 0;
    right: var(--spacer-2);
    left: var(--spacer-2);
    width: 100%;

    .wrapper {
      display: inline;
      position: relative;
    }

    .site-title {
      position: absolute;
      margin-top: 8px;
    }

    .site-nav {
      position: absolute;
      right: 40px;
    }

    ul#navbar li {
      margin: 0px;
      text-align: right;
    }
    
    ul#navbar li a {
      font-weight: normal;
      background: black;
      color: white;
      padding: 8px;
      text-decoration: none;
      border: none;
    }
    
    ul#navbar li a:hover {
      background: white;
      color: black;
    }

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 20px;
      text-align: center;

      > svg {
        fill: var(--heading-color);
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .site-nav {
      position: static;
      right: 40px;
    }

    ul#navbar {
      display: block;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}

/* Home page */

.home-block {
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 70vw;
  position: relative;
  left: calc(-35vw + 50%);
}

.home-block img {
  max-width: 45%;
}

@media (max-width: 1276px) {
  .home-block {
    left: 0;
    width: 70%;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .home-block img {
    max-width: 100%;
    z-index: -1;
  }
}

@media (max-width: 768px) {
  .home-block {
    left: 0;
    width: 100%;
    flex-wrap: wrap
  }
}


/* Projects */

.projects ul {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  padding-left: 0;
  gap: 1.8rem;
  justify-content: space-between;
}

.project-title {
  padding-bottom: 1rem;
}

.project-title h2, h3, .year {
  display: inline;
}

#featured-project-list li {
  max-width: 45%
}

#non-featured-project-list li {
  max-width: 30%
}

@media (max-width: 768px) {
#featured-project-list li {
  max-width: 100%;
}
#non-featured-project-list li {
  max-width: 45%;
}
}

/* Figures */

figure {
  padding-bottom: 1rem;
}

figcaption {
  font-size: 70%;
}

/* Galleries */
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.gallery-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gallery-item {
  width: calc(25% - 10px); /* 4 columns */
  box-sizing: border-box;
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
}

/* Fonts */

@font-face {
    font-family: 'Public Sans';
    src: url('/assets/fonts/PublicSans-Bold.woff2') format('woff2'),
        url('/assets/fonts/PublicSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('/assets/fonts/PublicSans-Regular.woff2') format('woff2'),
        url('/assets/fonts/PublicSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Public Sans';
    src: url('/assets/fonts/PublicSans-Italic.woff2') format('woff2'),
        url('/assets/fonts/PublicSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('assets/fonts/Raleway-Regular.woff2') format('woff2'),
        url('assets/fonts/Raleway-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('assets/fonts/Raleway-Bold.woff2') format('woff2'),
        url('assets/fonts/Raleway-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
