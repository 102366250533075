body {
  border-top: 10px solid black;
}

header {
  min-height: 100px;
  position: relative;
}

.container {
  max-width: 55rem;
}

main {
  @media (max-width: 650px) {
    padding-top: 100px;
  }
}

.blog-page {
  max-width: 45rem;
  margin-left: auto;
  margin-right: auto;
}

.site-title {
  margin-top: 3rem;
}

a {
  font-weight: bold;
  text-decoration: none;
}

:root {
  --link-color: var(--gray-700)
}

a:hover {
  color: #007eff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --link-color: var(--gray-300);
  }
}

ul {
  list-style: square;
}

.footer {
  text-align: right;
}

/* Navbar stuff */

header.masthead .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

ul#navbar {
  list-style: none;
  display: flex;
  margin-top: 12px;
  float: right;
}

.site-title {
  margin-top: 0px;
  a, a:visited {
    color: var(--heading-color);
  }
  a:hover {
    color: #007eff;
  }
}

ul#navbar li {
  padding: 0px;
  margin: 0px;
  margin-left: 10px;
}

ul#navbar li a {
  font-weight: normal;
  background: black;
  color: white;
  padding: 9px 13px;
  text-decoration: none;
  border: 1px solid #ccc;
}

ul#navbar li a:hover {
  background: white;
  color: black;
}

.nav-trigger {
  display: none;
}

.menu-icon {
  display: none;
}

.page-link {
  color: black;
  line-height: 1.5;

  // Gaps between nav items, but not on the last one
  &:not(:last-child) {
    margin-right: 20px;
  }
}

.masthead {
  @media (max-width: 650px) {
    position: absolute;
    padding-right: 40px;
    top: 9px;
    right: var(--spacer-2);
    left: var(--spacer-2);
    width: 100%;

    .site-title {
      margin-top: 8px;
    }

    ul#navbar li {
      margin: 0px;
      text-align: right;
    }
    
    ul#navbar li a {
      font-weight: normal;
      background: black;
      color: white;
      padding: 8px;
      text-decoration: none;
      border: none;
    }
    
    ul#navbar li a:hover {
      background: white;
      color: black;
    }

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 20px;
      text-align: center;

      > svg {
        fill: var(--heading-color);
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    ul#navbar {
      display: block;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}

/* Home page */

.home-block {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.home-block div img {
  max-width: 45%;
}

@media (max-width: 650px) {
.home-block {
  flex-wrap: wrap
}
.home-block div img {
  max-width: 100%;
  }
}

/* Projects */

.projects ul {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  padding-left: 0;
  gap: 1.8rem;
  justify-content: space-between;
}

.project-title {
  padding-bottom: 1rem;
}

.project-title h2, h3, .year {
  display: inline;
}

#featured-project-list li {
  max-width: 45%
}

#non-featured-project-list li {
  max-width: 30%
}

@media (max-width: 650px) {
#featured-project-list li {
  max-width: 100%;
}
#non-featured-project-list li {
  max-width: 45%;
}
}

/* Figures */

figure {
  padding-bottom: 1rem;
}

figcaption {
  font-size: 80%;
}


